<template>
  <container-list-extend title="Acces" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <BaseIcon name="box" class="float-right"></BaseIcon>
        <h6 class="m-0 ml-3" style="line-height: 1rem">{{ materiel.description }}</h6>
      </div>
    </template>
    <template v-slot:form>
      <base-form :loading="loading" deleteBtn @click="updateMateriel(materiel)" @delete="deleteMateriel(materiel)">
        <base-form-row row>
          <base-select
            inputText="Type"
            v-model.number="materiel.materiel_type_id"
            :options="data.materielTypes"
          ></base-select>
          <base-select
            inputText="Fournisseur"
            v-model.number="materiel.materiel_fournisseur_id"
            :options="data.materielFournisseurs"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <BaseInput
            class
            v-model="materiel.description"
            inputText="Description"
            :errors="feedback.description"
            :iconActive="true"
          ></BaseInput>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker v-model="materiel.date_commande" inputText="Date de commande"></base-inputDatePicker>
          <base-inputDatePicker v-model="materiel.date_livraison" inputText="Date de livraison"></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker v-model="materiel.date_validite" inputText="Date de validité"></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <BaseInput
            class
            v-model="materiel.num_serie"
            inputText="N° de série"
            :errors="feedback.num_serie"
          ></BaseInput>
        </base-form-row>
        <base-form-row row>
          <BaseInput
            class
            v-model="materiel.lieu_stockage"
            inputText="Lieu de stockage"
            :errors="feedback.lieu_stockage"
          ></BaseInput>
        </base-form-row>
        <base-form-row row>
          <BaseInput class v-model="materiel.marque" inputText="Marque" :errors="feedback.marque"></BaseInput>
        </base-form-row>
        <base-form-row row>
          <BaseInput class v-model="materiel.modele" inputText="Modèle" :errors="feedback.modele"></BaseInput>
        </base-form-row>
        <base-form-row row>
          <base-input-area v-model="materiel.commentaire" text="Commentaire"></base-input-area>
        </base-form-row>
        <uploader
          v-if="materiel.id"
          class="mt-5"
          id="document_materiel_suivi"
          url="/upload/document"
          title="Vos fichiers"
          :file_system="'MATERIEL'"
          :file_field="'materiel_suivi'"
          :file_key="materiel.id"
        ></uploader>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import BaseIcon from "@/components/bases/Icon.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  name: "AccesItem",
  components: {
    BaseIcon,
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseSelect,
    BaseInput,
    BaseInputDatePicker,
    BaseInputArea,
    Uploader,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      tabView: "infos",
      statut: null,
    };
  },
  props: ["materiel"],
  computed: {
    ...mapGetters({
      data: "collaborateurs/data",
    }),
  },
  methods: {
    ...mapActions({
      _updateMateriel: "collaborateurs/updateMateriel",
      _deleteMateriel: "collaborateurs/deleteMateriel",
    }),
    updateMateriel: function (materiel) {
      this.feedback = {};
      this.loading = true;
      this._updateMateriel(materiel)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteMateriel: function (materiel) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteMateriel(materiel)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="css"></style>
