<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <BaseToolBar title="Matériels" subtitle="Liste des matériels attribués">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter un matériel'"
        @click="storeMateriel"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="materiel in materiels" :key="materiel.id">
          <materiels-item :materiel="materiel"></materiels-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

import MaterielsItem from "@/components/collaborateurs/profil/forms/materiel/MaterielsItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: {
    MaterielsItem,
    BaseToolBar,
    BaseButton,
  },
  data() {
    return {
      newMateriel: {},
      feedback: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      _storeMateriel: "collaborateurs/createMateriel",
    }),
    storeMateriel: function () {
      this.feedback = {};
      this.loading = true;
      this.newMateriel = {
        materiel_collab_id: this.id,
      };
      this._storeMateriel(this.newMateriel)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {},
  computed: {
    ...mapFields("collaborateurs", ["collaborateur.id"]),
    ...mapMultiRowFields("collaborateurs", ["materiels"]),
    ...mapGetters({
      data: "collaborateurs/data",
    }),
  },
};
</script>
<style lang="css"></style>
